import { DatePipe, formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import jspdf from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/shared-module/constants';
import { GlobalResponse } from 'src/app/shared-module/models';
import { NonSecureService } from '../non-secure.service';

@Component({
  selector: 'app-clinic-invoice-template',
  templateUrl: 'clinic-invoice-template.component.html',
  styleUrls: ['clinic-invoice-template.component.scss'],
  providers: [DatePipe]
})
export class ClinicInvoiceTemplateComponent implements OnInit {
  mainData: any = {};
  invoice_id: string;
  base64ImageString: any;
  rowData: any[] = [];
  taxData: any[] = [];
  @BlockUI() blockUI: NgBlockUI;
  subTotal = 0;
  tax_amount = 0;
  line_discount_amount = 0;

  showTax: boolean = true;
  toggleTaxColumn() {
    this.showTax = !this.showTax;
  }

  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private service: NonSecureService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.invoice_id = this.route.snapshot.paramMap.get('invoice_id');
    if (this.invoice_id.length > 10) {
      this.fetchInvoiceDetails();
    } else {
      this.router.navigate([Constants.PET_DASHBOARD_URL]);
    }
  }

  fetchInvoiceDetails() {
    this.blockUI.start();
    this.service.fetchInvoiceDetails(this.invoice_id).subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS && res.result) {
          this.mainData = res.result;
          this.subTotal = Math.floor(this.mainData.discount + this.mainData.amount);
          this.titleService.setTitle('Invoice ' + this.mainData.invoiceid);
          this.initData();
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  initData() {
    this.createMainData(this.mainData.medicines, 'medicine');
    this.createMainData(this.mainData.disposables, 'disposable', Constants.DISPOSABLE_VALUE);
    this.createMainData(this.mainData.injectables, 'injectable', Constants.INJECTABLE_VALUE);
    this.createMainData(this.mainData.consumables, 'consumable', Constants.PRODUCT_VALUE);
    this.createMainData(this.mainData.other_services, 'other_service', Constants.SERVICES_VALUE);
    this.createMainData(this.mainData.vaccinations, 'vaccination', Constants.VACCINATION_VALUE);
    this.createMainData(this.mainData.labs, 'lab', Constants.DIAGNOSTIC_LAB_VALUE);
    this.createMainData(this.mainData.surgeries, 'surgery', Constants.PROCEDURE_SURGERY_VALUE);
    this.createMainData(this.mainData.checkup.veterinary_charge, 'Veterinary Charges', 'Charges');
    this.createMainData(this.mainData.checkup.consultation_charge, 'Consultation Charges', 'Charges');
    if (this.mainData.tax) {
      this.taxData = this.mainData.tax;
      this.tax_amount = this.sumArrayValue(this.mainData.tax);
    }
    if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
      this.getBase64ImageFromUrl(this.mainData.clinic.logo_url).then(base64 => {
        this.base64ImageString = base64;
      });
    }
    if (window.innerWidth <= 700) {
      setTimeout(() => {
        this.actionButton('print');
        window.close();
      }, 700);
    }
  }

  createMainData(listData: any, valueType: string, type: string = '') {
    if (type === 'Charges') {
      if (listData !== 0) {
        const tempItem = {} as any;
        tempItem.name = valueType;
        tempItem.price = listData;
        tempItem.total_price = listData;
        this.rowData.push(tempItem);
      }
    } else {
      for (const item of listData) {
        const tempItem = {} as any;
        if (item[valueType]) {
          tempItem.name = item[valueType].name;
          if (type) {
            tempItem.category = type;
          } else {
            tempItem.category = item[valueType].category;
          }

          if (
            tempItem.category === Constants.SERVICES_VALUE ||
            tempItem.category === Constants.DIAGNOSTIC_LAB_VALUE ||
            tempItem.category === Constants.PROCEDURE_SURGERY_VALUE
          ) {
            tempItem.price = item.price;
            tempItem.total_price = item.price;
            tempItem.qty = 1;
          } else {
            tempItem.qty = item.qty;
            tempItem.price = item.batches[0].price;
            tempItem.total_price = item.total_price;
          }
          // this.changableTotalPrice += item[valueType].total_price;
          tempItem._id = item[valueType]._id;
          tempItem.original_unit_price = item[valueType].original_unit_price;
          tempItem.hsn = item[valueType].hsn;
          tempItem.original_total_price = item[valueType].original_total_price;
          tempItem.discount = item[valueType].discount;
          this.line_discount_amount += item[valueType].discount;
          tempItem.drug = item[valueType].drug;
          tempItem.tax = item[valueType].tax_slab;
          tempItem.batch = item[valueType].batch ? item[valueType].batch : null;
          if (tempItem.price === 0) {
            // if (valueType === 'lab' || valueType === 'surgery') {
            this.rowData.push(tempItem);
            // } else {
            //   // this.nonInvData.push(tempItem);
            // }
          } else {
            this.rowData.push(tempItem);
          }
        }
      }
    }
  }

  sumArrayValue(array) {
    if (array && array.length > 0) {
      return array.map(obj => obj.amount).reduce((accumulator, current) => accumulator + current, 0);
    } else {
      return 0;
    }
  }

  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.onerror = () => reject(this);
      reader.readAsDataURL(blob);
    });
  }

  actionButton(type: string) {
    const doc = new jspdf('p', 'mm', 'a4');

    doc.setFontSize(18);
    doc.setTextColor(40);
    if (this.showTax === false) {
      if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
        doc.addImage(this.base64ImageString, 170, 5, 20 * 1.5, 20);
      }

      if (this.mainData.clinic && this.mainData.clinic.clinic_gst) {
        doc.line(10, 40, 200, 40);
      } else {
        doc.line(10, 42, 200, 42);
      }

      if (this.mainData.checkup && this.mainData.checkup.department && this.mainData.checkup.department === 'IPD') {
        doc.line(10, 76, 200, 76);
      } else {
        if (this.mainData.clinic && this.mainData.clinic.clinic_gst) {
          doc.line(10, 71, 200, 71);
        } else {
          doc.line(10, 66, 200, 66);
        }
      }
    } else {
      if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10 && this.base64ImageString) {
        doc.addImage(this.base64ImageString, 170, 15, 20 * 2, 30);
      }

      if (this.mainData.clinic && this.mainData.clinic.clinic_gst) {
        doc.line(10, 47, 200, 47);
      } else {
        doc.line(10, 42, 200, 42);
      }

      if (this.mainData.checkup && this.mainData.checkup.department && this.mainData.checkup.department === 'IPD') {
        doc.line(10, 72, 200, 72);
      } else {
        if (
          (this.mainData.clinic && this.mainData.clinic.clinic_gst) ||
          (this.mainData.clinic && this.mainData.clinic.license_number)
        ) {
          doc.line(10, 72, 200, 72);
        } else {
          doc.line(10, 66, 200, 66);
        }
      }

      const tempArray = [];
      for (const [index, item] of this.rowData.entries()) {
        const tempObj: any = {} as any;
        tempObj.index = index + 1;
        tempObj.name = item.name;
        tempObj.category = item.category;
        tempObj.price = Number(item.price).toFixed(2);
        tempObj.original_unit_price =
          item.original_unit_price && item.original_unit_price >= 0
            ? Number(item.original_unit_price).toFixed(2)
            : Number(item.price).toFixed(2);
        tempObj.qty = item.qty;
        tempObj.hsn = item.hsn && item.hsn.code ? item.hsn.code : '';
        tempObj.expiry_date =
          item.batch && item.batch.expiry_date ? formatDate(new Date(item.batch.expiry_date), 'MM-yyyy', 'en-US') : '';
        tempObj.tax = item.tax;
        tempObj.discount = item.discount >= 0 ? Number(item.discount).toFixed(2) : '';
        tempObj.total_price = Number(item.total_price).toFixed(2);
        tempArray.push(tempObj);
      }

      let mainHeaderItem = [];
      if (this.showTax === true) {
        if (this.mainData.clinic && this.mainData.clinic.clinic_gst && this.mainData.clinic.license_number) {
          mainHeaderItem = [
            [
              {
                content: this.mainData.clinic.name,
                styles: {
                  halign: 'left',
                  fontSize: 20,
                  cellPadding: 1
                  // textColor: '#1e26e6'
                }
              }
            ],
            [
              {
                content: this.mainData.clinic.address,
                styles: {
                  halign: 'left',
                  cellPadding: 1,
                  fontSize: 7,
                  textColor: 'gray'
                }
              }
            ],
            [
              {
                content: 'Phone: ' + this.mainData.clinic.phone_no,
                styles: {
                  halign: 'left',
                  cellPadding: 1,
                  fontSize: 8
                }
              }
            ],
            [
              {
                content:
                  'GST: ' +
                  this.mainData.clinic.clinic_gst +
                  '          ' +
                  'License: ' +
                  this.mainData.clinic.license_number,
                styles: {
                  halign: 'left',
                  cellPadding: 1,
                  fontSize: 8
                }
              }
            ]
          ];
        } else if (this.mainData.clinic && this.mainData.clinic.clinic_gst && !this.mainData.clinic.license_number) {
          mainHeaderItem = [
            [
              {
                content: this.mainData.clinic.name,
                styles: {
                  halign: 'left',
                  fontSize: 20,
                  cellPadding: 1
                  // textColor: '#1e26e6'
                }
              }
            ],
            [
              {
                content: this.mainData.clinic.address,
                styles: {
                  halign: 'left',
                  cellPadding: 1,
                  fontSize: 7,
                  textColor: 'gray'
                }
              }
            ],
            [
              {
                content: 'Phone: ' + this.mainData.clinic.phone_no,
                styles: {
                  halign: 'left',
                  cellPadding: 1,
                  fontSize: 8
                }
              }
            ],
            [
              {
                content: 'GST: ' + this.mainData.clinic.clinic_gst,
                // ,
                styles: {
                  halign: 'left',
                  cellPadding: 1,
                  fontSize: 8
                }
              }
            ]
          ];
        }
      } else if (this.mainData.clinic && !this.mainData.clinic.clinic_gst && this.mainData.clinic.license_number) {
        mainHeaderItem = [
          [
            {
              content: this.mainData.clinic.name,
              styles: {
                halign: 'left',
                fontSize: 20,
                cellPadding: 1
                // textColor: '#1e26e6'
              }
            }
          ],
          [
            {
              content: this.mainData.clinic.address,
              styles: {
                halign: 'left',
                cellPadding: 1,
                fontSize: 7,
                textColor: 'gray'
              }
            }
          ],
          [
            {
              content: 'Phone: ' + this.mainData.clinic.phone_no,
              styles: {
                halign: 'left',
                cellPadding: 1,
                fontSize: 8
              }
            }
          ],
          [
            {
              content: 'License: ' + this.mainData.clinic.license_number,
              // ,
              styles: {
                halign: 'left',
                cellPadding: 1,
                fontSize: 8
              }
            }
          ]
        ];
      } else {
        mainHeaderItem = [
          [
            {
              content: this.mainData.clinic.name,
              styles: {
                halign: 'left',
                fontSize: 20,
                cellPadding: 1
                // textColor: '#1e26e6'
              }
            }
          ],
          [
            {
              content: this.mainData.clinic.address,
              styles: {
                halign: 'left',
                cellPadding: 1,
                fontSize: 7,
                textColor: 'gray'
              }
            }
          ],
          [
            {
              content: 'Phone: ' + this.mainData.clinic.phone_no,
              styles: {
                halign: 'left',
                cellPadding: 1,
                fontSize: 8
              }
            }
          ]
        ];
      }

      autoTable(doc, {
        body: mainHeaderItem,
        theme: 'plain'
      });
      const headerDetailsArrayRow1 = [
        {
          content: 'Pet Name: ' + this.mainData.pet.name,
          styles: {
            halign: 'left'
          }
        },
        {
          content: 'Pet ID: ' + this.mainData.pet.pet_id,
          styles: {
            halign: 'left'
          }
        },
        {
          content: ''
        },
        {
          content: 'Date: ' + this.datepipe.transform(this.mainData.date, 'dd-MM-yyyy'),
          styles: {
            halign: 'left'
          }
        }
      ];
      const headerDetailsArrayRow2 = [
        {
          content: 'Owner Name: ' + this.mainData.customer.first_name + ' ' + this.mainData.customer.last_name,
          styles: {
            halign: 'left'
          }
        },
        {
          content: 'Owner Phone No: ' + this.mainData.customer.phone_no,
          styles: {
            halign: 'left'
          }
        },
        {
          content: ''
        },
        {
          content: 'Bill No: ' + this.mainData.invoiceid,
          styles: {
            halign: 'left'
          }
        }
      ];
      const headerDetailsArrayRow3 = [
        {
          content:
            'Prescribed By: ' +
            ' ' +
            this.mainData.prescribed_by.salutation +
            ' ' +
            this.mainData.prescribed_by.first_name +
            ' ' +
            this.mainData.prescribed_by.last_name,

          styles: {
            halign: 'left'
          }
        },
        {
          content: 'Department: ' + this.mainData.checkup.department,
          styles: {
            halign: 'left'
          }
        },
        {
          content: ''
        },
        {
          content: 'Channel: ' + this.mainData.checkup.channel,
          styles: {
            halign: 'left'
          }
        }
      ];
      const headerDetailsArrayRow4 = [
        {
          content: 'Check In Date: ' + this.datepipe.transform(this.mainData.checkup.check_in_date, 'dd-MM-yyyy'),
          styles: {
            halign: 'left'
          }
        },
        {
          content: 'Check Out Date: ' + this.datepipe.transform(this.mainData.checkup.check_out_date, 'dd-MM-yyyy'),
          styles: {
            halign: 'left'
          }
        }
      ];
      const headerArray = [];
      headerArray.push(headerDetailsArrayRow1);
      headerArray.push(headerDetailsArrayRow2);
      headerArray.push(headerDetailsArrayRow3);
      if (this.mainData.checkup && this.mainData.checkup.department && this.mainData.checkup.department === 'IPD') {
        headerArray.push(headerDetailsArrayRow4);
      }
      autoTable(doc, {
        body: headerArray,
        theme: 'plain'
      });

      if (!this.showTax) {
        autoTable(doc, {
          head: [['#', 'Name', 'Category', 'Qty', 'HSN', 'Expiry', 'MRP', 'Discount', 'Price', 'Total']],
          body: tempArray,
          columns: [
            { header: '#', dataKey: 'index' },
            { header: 'Name', dataKey: 'name' },
            { header: 'Category', dataKey: 'category' },
            { header: 'Qty', dataKey: 'qty' },
            // { header: 'Tax %', dataKey: 'tax' },
            { header: 'HSN', dataKey: 'hsn' },
            { header: 'Expiry', dataKey: 'expiry_date' },
            { header: 'MRP', dataKey: 'original_unit_price' },
            { header: 'Discount', dataKey: 'discount' },
            { header: 'Price', dataKey: 'price' },
            { header: 'Total', dataKey: 'total_price' }
          ],
          theme: 'striped',
          tableLineWidth: 0.2,
          headStyles: {
            fillColor: '#3b21db',
            fontSize: 8
          },
          columnStyles: {
            0: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            1: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            2: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            3: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            4: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            5: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            6: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            7: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            8: { halign: 'left', cellWidth: 'auto', fontSize: 8 }
          }
        });
        const bottomArrayItem1 = [
          {
            content: 'Sub-Total (in Rs):',
            styles: {
              halign: 'right'
            }
          },
          {
            content: this.subTotal,
            styles: {
              halign: 'right'
            }
          }
        ];

        const bottomArrayItem2 = [
          {
            content: 'Addn Discount (in Rs):',
            styles: {
              halign: 'right'
            }
          },
          {
            content: this.mainData.discount,
            styles: {
              halign: 'right'
            }
          }
        ];

        // const bottomArrayItem3 = [
        //   {
        //     content: 'Total Tax (in Rs):',
        //     styles: {
        //       halign: 'right'
        //     }
        //   },
        //   {
        //     content: this.tax_amount.toFixed(2),
        //     styles: {
        //       halign: 'right'
        //     }
        //   }
        // ];

        const bottomArrayItem4 = [
          {
            content: 'Advance Paid (in Rs):',
            styles: {
              halign: 'right'
            }
          },
          {
            content: this.mainData.checkup.advance_paid,
            styles: {
              halign: 'right'
            }
          }
        ];
        const bottomArrayItem5 = [
          {
            content: 'Total Billed Amount (in Rs):',
            styles: {
              halign: 'right',
              fontStyle: 'bold'
            }
          },
          {
            content: this.mainData.amount,
            styles: {
              halign: 'right',
              fontStyle: 'bold'
            }
          }
        ];

        const bottomArray = [];
        bottomArray.push(bottomArrayItem1);
        bottomArray.push(bottomArrayItem2);

        // for (const tItem of this.taxData) {
        //   if (tItem.tax_slab > 0) {
        //     const tempCTaxItem = [
        //       {
        //         content: 'CGST (' + tItem.tax_slab / 2 + '%):',
        //         styles: {
        //           halign: 'right',
        //           fontSize: 7
        //         }
        //       },
        //       {
        //         content: (tItem.amount / 2).toFixed(2),
        //         styles: {
        //           halign: 'right',
        //           fontSize: 7
        //         }
        //       }
        //     ];
        //     const tempSTaxItem = [
        //       {
        //         content: 'SGST (' + tItem.tax_slab / 2 + '%):',
        //         styles: {
        //           halign: 'right',
        //           fontSize: 7
        //         }
        //       },
        //       {
        //         content: (tItem.amount / 2).toFixed(2),
        //         styles: {
        //           halign: 'right',
        //           fontSize: 7
        //         }
        //       }
        // ];
        //     bottomArray.push(tempSTaxItem);
        //     bottomArray.push(tempCTaxItem);
        //   }
        // }

        // bottomArray.push(bottomArrayItem3);
        if (this.mainData.checkup && this.mainData.checkup.advance_paid && this.mainData.checkup.advance_paid > 0) {
          bottomArray.push(bottomArrayItem4);
        }
        bottomArray.push(bottomArrayItem5);
        autoTable(doc, {
          body: bottomArray,
          theme: 'plain'
        });
      } else {
        autoTable(doc, {
          head: [['#', 'Name', 'Category', 'Qty', 'Tax %', 'HSN', 'Expiry', 'MRP', 'Discount', 'Price', 'Total']],
          body: tempArray,
          columns: [
            { header: '#', dataKey: 'index' },
            { header: 'Name', dataKey: 'name' },
            { header: 'Category', dataKey: 'category' },
            { header: 'Qty', dataKey: 'qty' },
            { header: 'Tax %', dataKey: 'tax' },
            { header: 'HSN', dataKey: 'hsn' },
            { header: 'Expiry', dataKey: 'expiry_date' },
            { header: 'MRP', dataKey: 'original_unit_price' },
            { header: 'Discount', dataKey: 'discount' },
            { header: 'Price', dataKey: 'price' },
            { header: 'Total', dataKey: 'total_price' }
          ],
          theme: 'striped',
          tableLineWidth: 0.2,
          headStyles: {
            fillColor: '#3b21db',
            fontSize: 8
          },
          columnStyles: {
            0: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            1: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            2: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            3: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            4: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            5: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            6: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            7: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
            8: { halign: 'left', cellWidth: 'auto', fontSize: 8 }
          }
        });
        const bottomArrayItem1 = [
          { content: '' },
          { content: '' },
          {
            content: 'Sub-Total (in Rs):',
            styles: {
              halign: 'right'
            }
          },
          {
            content: this.subTotal,
            styles: {
              halign: 'left'
            }
          }
        ];

        const bottomArrayItem2 = [
          { content: '' },
          { content: '' },
          {
            content: 'Addn Discount (in Rs):',
            styles: {
              halign: 'right'
            }
          },
          {
            content: this.mainData.discount,
            styles: {
              halign: 'left'
            }
          }
        ];

        const bottomArrayItem3 = [
          { content: '' },
          { content: '' },
          {
            content: 'Total Tax (in Rs):',
            styles: {
              halign: 'right'
            }
          },
          {
            content: this.tax_amount.toFixed(2),
            styles: {
              halign: 'left'
            }
          }
        ];

        const bottomArrayItem4 = [
          { content: '' },
          { content: '' },
          {
            content: 'Advance Paid (in Rs):',
            styles: {
              halign: 'right'
            }
          },
          {
            content: this.mainData.checkup.advance_paid,
            styles: {
              halign: 'left'
            }
          }
        ];
        const bottomArrayItem5 = [
          { content: '' },
          { content: '' },
          {
            content: 'Total Billed Amount (in Rs):',
            styles: {
              halign: 'right',
              fontStyle: 'bold'
            }
          },
          {
            content: this.mainData.amount,
            styles: {
              halign: 'left',
              fontStyle: 'bold'
            }
          }
        ];

        const bottomArray = [];
        bottomArray.push(bottomArrayItem1);
        if (this.mainData.discount && this.mainData.discount > 0) {
          bottomArray.push(bottomArrayItem2);
        }

        for (const tItem of this.taxData) {
          if (tItem.tax_slab > 0) {
            const tempCTaxItem = [
              { content: '' },
              { content: '' },
              {
                content: 'CGST (' + tItem.tax_slab / 2 + '%):',
                styles: {
                  halign: 'right',
                  fontSize: 7
                }
              },
              {
                content: (tItem.amount / 2).toFixed(2),
                styles: {
                  halign: 'right',
                  fontSize: 7
                }
              }
            ];
            const tempSTaxItem = [
              { content: '' },
              { content: '' },
              {
                content: 'SGST (' + tItem.tax_slab / 2 + '%):',
                styles: {
                  halign: 'right',
                  fontSize: 7
                }
              },
              {
                content: (tItem.amount / 2).toFixed(2),
                styles: {
                  halign: 'right',
                  fontSize: 7
                }
              }
            ];

            bottomArray.push(tempSTaxItem);
            bottomArray.push(tempCTaxItem);
          }
        }

        bottomArray.push(bottomArrayItem3);
        if (this.mainData.checkup && this.mainData.checkup.advance_paid && this.mainData.checkup.advance_paid > 0) {
          bottomArray.push(bottomArrayItem4);
        }
        bottomArray.push(bottomArrayItem5);

        const completedByItem = [
          {
            content:
              'Completed By: ' +
              this.mainData.completed_by.salutation +
              ' ' +
              this.mainData.completed_by.first_name +
              ' ' +
              this.mainData.completed_by.last_name,
            styles: {
              halign: 'left',
              fontSize: 7
            }
          },
          { content: '' },
          { content: '' }
        ];
        bottomArray.push(completedByItem);

        autoTable(doc, {
          body: bottomArray,
          theme: 'plain'
        });

        autoTable(doc, {
          body: [
            [
              {
                content: 'Powered by PawsNme',
                styles: {
                  halign: 'center',
                  textColor: 'black'
                }
              }
            ],
            [
              {
                content: 'This is Computer Generated Invoice, No Signature Required',
                styles: {
                  halign: 'center'
                }
              }
            ]
          ],
          theme: 'plain'
        });
        if (type === 'download') {
          return doc.save('Invoice_' + this.mainData.invoiceid + '.pdf');
        } else if (type === 'print') {
          doc.autoPrint({ variant: 'non-conform' });
          const blob = doc.output('blob');
          window.open(URL.createObjectURL(blob));
        }
      }
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth <= 700) {
      setTimeout(() => {
        this.actionButton('download');
        window.close();
      }, 700);
    }
  }
}
